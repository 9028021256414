import { ListController } from '../../controllers/list-controller.class'

/** @ngInject */
class ProstoiAvtoCtrl extends ListController {
  constructor($scope, $injector, avtosService) {
    super($scope, $injector)
    this.tableOptions = {
      headers: {
        mark: 'Марка',
        nomer: 'Номер&nbsp;тягача',
        polu: 'Закрепленный полуприцеп',
        driver: 'Закрепленный водитель',
        prostoi: 'Дней простоя',
      },
    }
    this.api = avtosService
  }

  get defaultFilters() {
    return {
      prostoi: 60,
    }
  }
}

export const ProstoiAvtoComponent = {
  template: require('./prostoi-avto.component.html'),
  styles: [require('./prostoi-avto.component.scss')],
  bindings: {},
  controller: ProstoiAvtoCtrl,
}
