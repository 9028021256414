import { CRUDService } from './crud-service.class'
import utils from '../utils'

export class TachographsService extends CRUDService {
  subject = 'tachs'

  parse = (p) => {
    p.id = parseInt(p.id)
    p.valid_to = p.valid_to ? utils.db.str2date(p.valid_to) : null
    p.full_name = `${p.mark} (тип ${p.type}, с/н: ${p.serial})`
    return p
  }

  format = (o) => {
    o.valid_to = o.valid_to ? utils.db.date2str(o.valid_to) : null
    return o
  }
}
