import './TableWidget'

/** Контроллер для таблицы JsTable. */
export class JsTableController {
  static $inject = ['$element', '$timeout']

  /** DOM-эелемент таблицы. */
  el = null
  /** jQuery-обертка над DOM-элементом таблицы. */
  $el = null
  /** Время последней модификации. */
  lastModDate = new Date()
  /** Режим автозагрузки. */
  autoload = false
  /** Постраничный вывод. */
  pagination = false

  /** Конструктор компонента. */
  constructor($element, $timeout) {
    Object.assign(this, { $element, $timeout })
    this.el = $element.find('table')
    this.$el = $(this.el)
  }

  /** Инициализация компонента. */
  $onInit() {
    this.$el.jsTable(Object.assign(this.options, {}))

    this.$el.jsTable('option', 'onRowClick', () => {
      const nv = this.getSelectedRow()
      this.ngModelCtrl.$setViewValue(nv.id)
      this.$timeout(() => {
        this.onRowClick({ data: nv })
      })
    })

    this.$el.jsTable('option', 'onRowDblClick', this.onRowDblClick)

    this.ngModelCtrl.$render = () => {
      if (this.ngModelCtrl.$viewValue) {
        this.$el.jsTable('selectRowById', this.ngModelCtrl.$viewValue)
      }
    }

    this.rowRenderer = this.rowRenderer || ((row) => row)
    this.$el.jsTable('option', 'rowRenderer', this.rowRenderer)

    // пошлем сигналы об окончании инициализации компонента
    const impl = this._createImplController()
    this.impl = impl
    this.onInit({ ctrl: impl })
    if (this.onInit$) {
      this.onInit$.resolve(impl)
    }
  }

  /** Реакция на изменение компонента. */
  $onChanges(changes) {
    if (changes.filtersJson) {
      // если не установлен флаг автозагрузки, то не реагируем
      // на первое изменение фильров
      if (changes.filtersJson.isFirstChange() && this.autoload === undefined) {
        return
      }

      const filterJson = changes.filtersJson.currentValue
      if (!filterJson) {
        return
      }

      const filters = JSON.parse(changes.filtersJson.currentValue)
      this.loadObjects(filters)
      return
    }

    // FIXME выпилить этот режим, он нигде не используется
    if (changes.rows) {
      if (changes.rows.isFirstChange()) return

      const rowsCV = changes.rows.currentValue || []
      this.loadRows(rowsCV)
    }
  }

  /**
   * Загрузка данных в таблицу.
   * @param {Object[]} rows данные для таблицы
   * @returns {Object[]} те же данные.
   */
  loadRows(rows) {
    this.$el.jsTable('loadRows', rows)
    // TODO if no appropriate row found set ngmodel to null
    this.ngModelCtrl.$render()
    this.lastModDate = new Date()
    this.onLoad({ data: rows })
    return rows
  }

  /**
   * Добавление строки в таблицу.
   * @param {Object} row строка таблицы.
   * @returns
   */
  addRow(row) {
    this.$el.jsTable('addRow', row)
    this.ngModelCtrl.$setViewValue(row.id)
    this.ngModelCtrl.$render()
    this.lastModDate = new Date()
  }

  /**
   * Удаление строки по id.
   * @param {number} id
   */
  removeRow(id) {
    this.$el.jsTable('removeRowById', id)
    const row = this.$el.jsTable('getSelectedRowData')
    if (row) {
      this.ngModelCtrl.$setViewValue(row.id)
    } else this.ngModelCtrl.$setViewValue(null)
    this.lastModDate = new Date()
  }

  /**
   * Обновление строки (идентификатор берется из нее).
   * @param {Object} обновление строки.
   */
  updateRow(row) {
    this.$el.jsTable('updateRowById', row.id, row)
    this.ngModelCtrl.$setViewValue(row.id)
    this.ngModelCtrl.$render()
    this.lastModDate = new Date()
  }

  /**
   * Добавить или обновить строку.
   * @param {Object} value объект.
   */
  addOrUpdateRow(value) {
    if (!value || !value.id) {
      return
    }
    if (this.impl.rows.find((r) => r.id === value.id) === undefined) {
      this.impl.addRow(value)
    } else {
      this.impl.updateRow(value)
    }
  }

  /**
   * Получить данные выбранной строки.
   * @returns {Object} данные для строки.
   */
  getSelectedRow() {
    return this.$el.jsTable('getSelectedRowData')
  }

  /**
   * Выбрать строку по идентификатору.
   * @param {number} id идентификатор.
   * @returns {HTMLElement} DOM-элемент строки.
   */
  selectRow(id) {
    return this.$el.jsTable('selectRowById', id)
  }

  /** Снять выделение. */
  clearSelection() {
    this.$el.jsTable('clearSelection')
  }

  /**
   * Создать объект и добавить строку на его основе.
   * @param {Object} obj объект модели.
   * @returns {Promise<Object>} сохраненный объект модели.
   */
  addObject(obj) {
    const noidObj = Object.assign({}, obj, { id: null })
    return this.saveObject(noidObj)
  }

  /**
   * Сохранить объект и обновить соответствующую строку.
   * @param {Object} obj объект модели.
   * @returns {Promise<Object>} сохраненный объект модели.
   */
  saveObject(obj) {
    return this.objectsSource.save(obj).then((saved) => {
      if (obj.id) {
        this.updateRow(saved)
      } else {
        this.addRow(saved)
      }
      return saved
    })
  }

  /**
   * Удалить объект и соответствующую строку.
   * @param {Object|number} obj объект модели или его идентификатор.
   */
  removeObject(obj) {
    let id
    if (isNaN(obj)) {
      id = obj.id
    } else {
      id = obj
    }
    this.objectsSource.delete(id).then(() => {
      this.removeRow(id)
    })
  }

  /**
   * Загрузить объекты на основе параметров запроса.
   * @param {Object} params параметры запроса.
   * @returns {Promise<Object[]>} загруженные объекты.
   */
  loadObjects(params) {
    return this.objectsSource.get(params).then((resp) => {
      let results = resp
      if (this.pagination) {
        results = resp.results
      }
      return this.loadRows(results)
    })
  }

  /**
   * Фильтры.
   * @returns {Object} фильтры на основе filtersJson.
   */
  get filters() {
    if (!this.filtersJson) {
      return {}
    }
    return JSON.parse(this.filtersJson)
  }

  /**
   * Перезагрузка данных таблицы на основе установленных фильтров.
   * @returns {Promise<Object[]>} загруженные объекты.
   */
  reload() {
    return this.loadObjects(this.filters)
  }

  /** Очистка таблицы. */
  clear() {
    this.ngModelCtrl.$setViewValue(null)
    this.lastModDate = new Date()
    this.loadRows([])
  }

  /** Получить данные выбранной строки. */
  selected() {
    return this.getSelectedRow()
  }

  getRowDataById(id) {
    const row = this.$el.jsTable('getRowById', id)
    return this.$el.jsTable('getRowData', row)
  }

  /**
   * Создание контроллера-интерфейса для внешнего использования.
   *
   * Идея в том, чтобы отделить компонент js-table от внешнего мира
   * через данный интерфейс. Это даст возможность покрыть интерфейс
   * тестами и застраховать пользователей контроллера от возможного
   * влияния изменений реализации. */
  _createImplController() {
    const impl = {
      loadRows: this.loadRows.bind(this),
      addRow: this.addRow.bind(this),
      removeRow: this.removeRow.bind(this),
      updateRow: this.updateRow.bind(this),
      addOrUpdateRow: this.addOrUpdateRow.bind(this),
      getSelectedRow: this.getSelectedRow.bind(this),
      selectRow: this.selectRow.bind(this),
      clearSelection: this.clearSelection.bind(this),
      getRowDataById: this.getRowDataById.bind(this),
      getRow: this.getRowDataById.bind(this),

      add: this.addObject.bind(this),
      save: this.saveObject.bind(this),
      remove: this.removeObject.bind(this),
      load: this.loadObjects.bind(this),
      reload: this.reload.bind(this),
      clear: this.clear.bind(this),
      selected: this.selected.bind(this),
    }
    Object.defineProperty(impl, 'selectedItem', {
      get: this.selected.bind(this),
    })
    Object.defineProperty(impl, 'nrows', {
      get: () => Object.values(this.$el.jsTable('getData')).length,
    })
    Object.defineProperty(impl, 'rows', {
      get: () => Object.values(this.$el.jsTable('getData')),
    })
    Object.defineProperty(impl, 'lastItem', {
      get: () => {
        const rows = Object.values(this.$el.jsTable('getData'))
        return rows.length > 0 ? rows[rows.length - 1] : undefined
      },
    })
    Object.defineProperty(impl, 'data', {
      get: () => this.$el.jsTable('getData'),
    })
    Object.defineProperty(impl, 'lastModDate', {
      get: () => this.lastModDate,
    })
    Object.defineProperty(impl, 'selectedObject', {
      get: this.getSelectedRow.bind(this),
    })
    return impl
  }
}

export default {
  template: `<table class="tablesorter full-width"></table>`,
  styles: [require('./js-table.component.css')],
  bindings: {
    options: '<',
    onRowClick: '&',
    onRowDblClick: '&',
    onLoad: '&',
    onInit: '&',
    onInit$: '=',
    rows: '<',
    objectsSource: '<',
    rowRenderer: '<',
    autoload: '@',
    pagination: '@',
    filtersJson: '<',
  },
  require: {
    ngModelCtrl: 'ngModel',
  },
  controller: JsTableController,
}
