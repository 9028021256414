import { ListController } from '../../controllers/list-controller.class'

/** @ngInject */
class OsfschetFormController extends ListController {
  constructor($scope, $injector, printer, popup, osfschetService, $state) {
    super($scope, $injector)
    this.printer = printer
    this.api = osfschetService
    this.popup = popup
    this.$state = $state
    this.tableOptions = {
      headers: {
        nn: 'п/н',
        firm: 'Фирма',
        schet_num: '№ счета',
        schet_data: 'Дата',
        curr: 'Валюта',
        file: 'Файл',
        sum_val: 'Сумма в валюте',
        sum_rub: 'Сумма в рублях',
        opl: '+/-',
        plat_n: 'Плат. поруч.',
        plat_sum: 'Сумма платежа',
        plat_data: 'Дата платежа',
        comment: 'Примечание',
      },
    }
    this.stats = {
      total_acc_sum: 0,
      total_plat_sum: 0,
      total_ost_sum: 0,
    }
  }

  get defaultFilters() {
    const params = this.$state.params
    return { _zid: params._zid || null, _firm: params._firm || 'all' }
  }

  rowFormatter(row) {
    return row
  }

  updateStats(data) {
    this.api.stats(this.filters).then((stats) => {
      this.stats = stats
    })
  }

  print() {
    this.printer.print('osfschet', this.filters)
  }

  printActs() {
    this.printer.print('vygr', { firm: this.filters._firm })
  }

  sverka() {
    this.printer.print('osfschet', { _type: 'sverka', ...this.filters })
  }
}

export const OsfschetFormComponent = {
  template: require('./osfschet-form.component.html'),
  styles: [require('./osfschet-form.component.scss')],
  bindings: {
    ourFirms: '<',
    zaid: '<',
  },
  controller: OsfschetFormController,
  controllerAs: 'vm',
}
