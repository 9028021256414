import { contractsState } from './contract.states'
import { plistAddState, plistListState, plistState } from './plist.states'
import { plansPricepUsageState, plansState } from './plans.states'
import { prostoiAvtoState } from './prostoi-avto.states'
import { ttnNewState, ttnDetailState, ttnBaseState } from './ttn.states'
import { safetyBriefingState } from './safety-briefing.states'
import { saleOfServicesState } from './sale-of-services.states'
import { technicalControlState } from './technical-control.states'
import { pricepEditState, pricepListState } from './pricep.states'
import './equipments.states'
import './firm.states'
import './zay.states'
import './tachograph.states'
import angular from 'angular'
import {
  ourFirms,
  ekspeditors,
  tarifi,
  uchetById,
  optFirm,
  avtos,
  managers,
} from './resolvers'

export default angular
  .module('gruz.states', [
    'gruz.states.equipment',
    'gruz.states.firms',
    'gruz.states.tachographs',
    'gruz.states.zays',
  ])
  .config([
    '$stateRegistryProvider',
    '$urlRouterProvider',
    function ($stateRegistry, $urlRouterProvider) {
      $urlRouterProvider.otherwise('/dashboard')

      $stateRegistry.register(contractsState)
      $stateRegistry.register(ttnBaseState)
      $stateRegistry.register(ttnNewState)
      $stateRegistry.register(ttnDetailState)
      $stateRegistry.register(plistListState)
      $stateRegistry.register(plistAddState)
      $stateRegistry.register(plistState)
      $stateRegistry.register(plansState)
      $stateRegistry.register(plansPricepUsageState)
      $stateRegistry.register(prostoiAvtoState)
      $stateRegistry.register(safetyBriefingState)
      $stateRegistry.register(saleOfServicesState)
      $stateRegistry.register(technicalControlState)
      $stateRegistry.register(pricepEditState)
      $stateRegistry.register(pricepListState)

      $stateRegistry.register({
        name: 'dashboard',
        url: '/dashboard',
        component: 'dashboard',
        resolve: {
          profile: (currentUser) => currentUser.getProfile(),
        },
        data: {
          title: 'Грузоперевозки',
        },
      })

      $stateRegistry.register({
        name: 'allavto',
        url: '/allavto?pid&selavto',
        component: 'allavtoForm',
        data: {
          title: 'Тягачи',
        },
        resolve: {
          avtos,
          firm: optFirm('pid'),
          ourFirms,
        },
      })

      $stateRegistry.register({
        name: 'balans',
        url: '/balans',
        component: 'balansForm',
        data: {
          title: 'Баланс',
        },
        resolve: {
          ekspeditors,
        },
      })

      $stateRegistry.register({
        name: 'correct',
        url: '/correct',
        component: 'correctForm',
        data: {
          title: 'Местонахождение автотранспорта',
        },
      })

      $stateRegistry.register({
        name: 'drivers',
        url: '/drivers',
        component: 'driversListForm',
        data: {
          title: 'Водители',
        },
      })

      $stateRegistry.register({
        name: 'gruzaper',
        url: '/gruzaper',
        component: 'gruzaperForm',
        data: {
          title: 'Вывезенный груз за период',
        },
        resolve: {
          ekspeditors,
        },
      })

      $stateRegistry.register({
        name: 'subshet',
        url: '/subshet',
        component: 'subshetForm',
        data: {
          title: 'Счета субконтракторов',
        },
      })

      $stateRegistry.register({
        name: 'nepofirme',
        url: '/nepofirme',
        component: 'nepofirmeForm',
        data: {
          title: 'Счета субконтраторов',
        },
        resolve: {
          zfirms: (nepofirmeService) => nepofirmeService.zfirms(),
          totalSum: (nepofirmeService) => nepofirmeService.totalSum(),
        },
      })

      $stateRegistry.register({
        name: 'ofsact',
        url: '/ofsact',
        component: 'ofsact',
        data: {
          title: 'Оформление счетов/актов',
        },
      })

      $stateRegistry.register({
        name: 'oneschet',
        url: '/oneschet?_zid&_firm',
        component: 'oneschetForm',
        data: {
          title: 'Невыставленные по счету',
        },
        resolve: {
          zaid: optFirm('_zid'),
          ekspeditors,
        },
      })

      $stateRegistry.register({
        name: 'onezakaz',
        url: '/onezakaz',
        component: 'onezakazForm',
        data: {
          title: 'Невыставленные по заказчику',
        },
      })

      $stateRegistry.register({
        name: 'onoseezak',
        url: '/onoseezak',
        component: 'onoseezakForm',
        data: {
          title: 'Неоплаченные по заказчику',
        },
      })

      $stateRegistry.register({
        name: 'onozakwin',
        url: '/onozakwin?_firm&_kmfirm',
        component: 'onozakwinForm',
        data: {
          title: 'Неоплаченные по счету',
        },
        resolve: {
          // zaid: optZaid('_firm'),
          payers: (onozakwinService, $stateParams) =>
            onozakwinService.payers({
              _firm: $stateParams._firm,
              _kmfirm: $stateParams._kmfirm,
            }),
          ourFirms,
        },
      })

      $stateRegistry.register({
        name: 'osfschet',
        url: '/osfschet?_zid&_firm',
        component: 'osfschetForm',
        data: {
          title: 'Счета фирмы по счету',
        },
        resolve: {
          zaid: optFirm('_zid'),
          ourFirms,
        },
      })

      $stateRegistry.register({
        name: 'osfzakaz',
        url: '/osfzakaz',
        component: 'osfzakazForm',
        data: {
          title: 'Счета фирмы по заказчику',
        },
      })

      $stateRegistry.register({
        name: 'pergruzi',
        url: '/pergruzi',
        component: 'pergruziForm',
        data: {
          title: 'Перевезенные грузы',
        },
      })

      $stateRegistry.register({
        name: 'planper',
        url: '/planper',
        component: 'planperForm',
        data: {
          title: 'План на период',
        },
        resolve: {
          ekspeditors,
        },
      })

      $stateRegistry.register({
        name: 'pricProbeg',
        url: '/pric-probeg',
        component: 'pricProbegForm',
        data: {
          title: 'Пробег прицепов',
        },
      })

      $stateRegistry.register({
        name: 'avtoJournal',
        url: '/journal',
        component: 'avtoJournalForm',
        resolve: {
          /** @ngInject */
          ourFirms,
        },
        data: {
          title: 'Журнал учета работы автотранспорта',
        },
      })

      $stateRegistry.register({
        name: 'arendaPolupricepov',
        url: '/arenda',
        component: 'arendaPolupricepovForm',
        resolve: {
          ourFirms,
        },
        data: {
          title: 'Аренда полуприцепов',
        },
      })

      $stateRegistry.register({
        name: 'ostfuel',
        url: '/ostfuel',
        component: 'ostFuelForm',
        resolve: {
          ourFirms,
        },
        data: {
          title: 'Остаток топлива',
        },
      })

      $stateRegistry.register({
        name: 'rashod',
        url: '/rashod',
        component: 'rashodToplivaForm',
        resolve: {
          ourFirms,
        },
        data: {
          title: 'Расход топлива',
        },
      })

      $stateRegistry.register({
        name: 'monthprobeg',
        url: '/monthprobeg',
        component: 'monthProbegForm',
        resolve: { ourFirms },
        data: {
          title: 'Пробег за месяц',
        },
      })

      $stateRegistry.register({
        name: 'osopzm',
        url: '/osopzm',
        component: 'osopzmForm',
        data: {
          title: 'Отчет страхователя о перевозках за месяц',
        },
      })

      $stateRegistry.register({
        name: 'otper2n',
        url: '/otper2n',
        component: 'otper2nForm',
        resolve: { ekspeditors },
        data: {
          title: 'Отчет 2 новый',
        },
      })

      $stateRegistry.register({
        name: 'otper2',
        url: '/otper2',
        component: 'otper2Form',
        data: {
          title: 'Отчет о перевозках 2',
        },
      })

      $stateRegistry.register({
        name: 'otper1',
        url: '/otper1',
        component: 'otper1Form',
        data: {
          title: 'Отчет о перевозках 1',
        },
      })

      $stateRegistry.register({
        name: 'otvyst',
        url: '/otvyst',
        component: 'otvystForm',
        resolve: { ekspeditors },
        data: {
          title: 'Отчет о выставленных счетах',
        },
      })

      $stateRegistry.register({
        name: 'tarifi',
        url: '/tarifi',
        component: 'tarifiForm',
        resolve: {
          tarifi,
        },
        data: {
          title: 'Тарифы грузоперевозок',
        },
      })

      $stateRegistry.register({
        name: 'tehosmotr',
        url: '/tehosmotr',
        component: 'tehosmotr',
        resolve: {
          firms: ourFirms,
        },
        data: {
          title: 'Техосмотр',
        },
      })

      $stateRegistry.register({
        name: 'strahovanie',
        url: '/strahovanie',
        component: 'strahovanie',
        resolve: {
          firms: ourFirms,
        },
        data: {
          title: 'Страхование',
        },
      })

      $stateRegistry.register({
        name: 'neopl',
        url: '/neopl',
        component: 'neoplForm',
        resolve: { ekspeditors },
        data: {
          title: 'Неоплаченные счета',
        },
      })

      $stateRegistry.register({
        name: 'fuelCost',
        url: '/fuel-cost',
        component: 'fuelCostForm',
        resolve: {
          fuelCost: (fuelCostServiceApiV2) =>
            fuelCostServiceApiV2.get({ id: 1 }),
        },
        data: {
          title: 'Цены на топливо',
        },
      })

      $stateRegistry.register({
        name: 'kintrans',
        url: '/kintrans',
        component: 'kintransList',
        data: {
          title: 'Банковские реквизиты',
        },
      })

      $stateRegistry.register({
        name: 'ctrlSchet',
        url: '/ctrl-schet',
        component: 'ctrlSchetForm',
        data: {
          title: 'Контроль невыставленных счетов',
        },
      })

      $stateRegistry.register({
        name: 'zayStatus',
        url: '/zay-status',
        component: 'zayStatus',
        data: {
          title: 'Статус заявок',
        },
        resolve: {
          managers,
        },
      })

      $stateRegistry.register({
        abstract: true,
        name: 'invoices',
        url: '/invoices',
      })

      $stateRegistry.register({
        abstract: true,
        name: 'invoices.base',
        url: '/:id',
        resolve: {
          uchet: uchetById,
        },
      })

      $stateRegistry.register({
        name: 'invoices.base.oformlenie',
        url: '/oformlenie',
        component: 'oformlenieForm',
        data: {
          title: 'Оформление',
        },
        resolve: {
          // адаптация старого компонента
          pid: (uchet) => uchet.nscheta,
          fid: (uchet) => uchet.firm,
          val: (uchet) => uchet.cur,
        },
      })

      $stateRegistry.register({
        name: 'invoices.base.plategi',
        url: '/plategi',
        component: 'plategiForm',
        data: {
          title: 'Платежные поручения',
        },
        resolve: {
          // адаптация старого компонента
          nscheta: (uchet) => uchet.nscheta,
          firm: (uchet) => uchet.firm,
          pid: (uchet) => uchet.pid,
          nomer: (uchet) => uchet.nomer,
        },
      })
    },
  ]).name
