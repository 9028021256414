import { CRUDService } from './crud-service.class'

export class BalansNalService extends CRUDService {
  subject = 'balance_nal'

  parse = (p) => {
    p.nvist_p = parseInt(p.nvist_p)
    p.nvist_m = parseInt(p.nvist_m)
    p.nvist_b = parseInt(p.nvist_b)
    p.nneop_p = parseInt(p.nneop_p)
    p.nneop_m = parseInt(p.nneop_m)
    p.nopl_p = parseInt(p.nopl_p)
    p.nopl_m = parseInt(p.nopl_m)
    p.nopl_b = parseInt(p.nopl_b)
    return p
  }
}
