/** @ngInject */
class DashboardController {
  constructor($scope, keycloakService, firmServiceApiV2) {
    $scope.vm = this
    this.menu = require('./menu.json')
    this.keycloakService = keycloakService
    this.firmServiceApiV2 = firmServiceApiV2

    /**
     * Дополнительные элементы меню.
     * @type {MenuItem}
     */
    this.extraItems = [
      {
        data: {
          zag: 'Техно-Марк',
          http: 'state:equipments',
          sost: '1',
          width: '1024',
          height: '768',
          roles: [
            'Реестр оборудования',
            'Администратор реестра оборудования',
            'Супервизор',
          ],
        },
        id: 10000,
        children: [],
      },
    ]
  }

  $onInit() {
    // FIXME: запрос к APIv2 нужен для создания моделей
    // пользователей, если их нет, т.к. APIv1 их не создает
    this.firmServiceApiV2.getOurFirms().then((resp) => {})
  }

  logout() {
    this.keycloakService.logout({
      redirectUri: window.location.origin + '/#!/dashboard',
    })
  }
}

export const DashboardComponent = {
  template: require('./dashboard.component.html'),
  styles: [require('./dashboard.component.scss')],
  bindings: {
    profile: '<',
  },
  controller: DashboardController,
}
