import { ModalFormCtrl } from '../../controllers/form-controller.class'

class DriverFormController extends ModalFormCtrl {
  /** @ngInject */
  constructor($scope, driverServiceApiV2) {
    super($scope)
    this.api = driverServiceApiV2
  }
}

export const DriverFormComponent = {
  template: require('./driver-form.component.html'),
  styles: [require('./driver-form.component.scss')],
  bindings: {
    model: '<',
    onClose: '&',
    ourFirms: '<',
  },
  controller: DriverFormController,
}
