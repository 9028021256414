import PopeyeModal from '../popeye-modal.class'

class AvtoModalController {
  constructor($scope, item, tachographs, filters) {
    this.$scope = $scope
    this.avto = item
    $scope.vm = this
    if (!this.avto) {
      this.avto = { pid: filters.pid }
    }
    this.tachographs = tachographs
  }
}

export class AvtoModal extends PopeyeModal {
  modal = {
    template: require('./avto.modal.html'),
    controller: AvtoModalController,
  }
}
