import { CRUDService } from './crud-service.class'

export class OnoseezakService extends CRUDService {
  subject = 'onoseezak'

  parse = (p) => {
    p._full_name = `${p.type} "${p.comp}"`
    return p
  }

  format = (o) => {
    return o
  }
}
