import { settings } from './app.config'

/**
 * Открытие диалогового окна.
 *
 * @param {string} path URL после '#!/'
 * @param {number} width длина окна (px)
 * @param {number} height высота окна (px)
 * @returns {Promise<object>} результат выхода из диалога
 */
export function ngOpenDialog(path, width, height) {
  const dfd = $.Deferred()
  const child = $.fn.openWindow(`#!/${path}`, width, height)
  child.dialog = dfd
  return dfd.promise()
}

export function fprint(file) {
  window.location = `${settings.apiV1Url}/print.php?_file=${file}`
}

export function printUrl(url) {
  window.location = `${settings.apiV1Url}/${url}`
}
