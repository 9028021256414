import { ModalFormCtrl } from '../../controllers/form-controller.class'

class CorrectPlaceFormController extends ModalFormCtrl {
  /** @ngInject */
  constructor($scope, $injector, correctService) {
    super($scope, $injector)
    this.api = correctService
  }

  $onInit() {
    if (!this.model) {
      this.model = {}
    }
    super.$onInit()
  }
}

export const CorrectPlaceFormComponent = {
  template: require('./correct-place-form.component.html'),
  styles: [require('./correct-place-form.component.scss')],
  bindings: {
    model: '<',
    onClose: '&',
  },
  controller: CorrectPlaceFormController,
}
