import { ListController } from '../../controllers/list-controller.class'

/** @ngInject */
class OnezakazFormController extends ListController {
  constructor($scope, $injector, printer, popup, firmServiceApiV2) {
    super($scope, $injector)
    $scope.vm = this
    this.printer = printer
    this.api = firmServiceApiV2
    this.popup = popup
    this.tableOptions = {
      headers: {
        _full_name: 'Фирма',
        inn: 'ИНН',
      },
    }
  }

  get defaultFilters() {
    return {
      _contains: null,
      inn__contains: null,
    }
  }

  rowFormatter(row) {
    row.inn = row.rekv.inn
    return row
  }

  view() {
    this.popup.state('oneschet', { _zid: this.selected }, 1024, 768)
  }

  print() {
    this.printer.print('vygr', { firm: this.selected })
  }
}

export const OnezakazFormComponent = {
  template: require('./onezakaz-form.component.html'),
  styles: [require('./onezakaz-form.component.scss')],
  bindings: {
    firms: '<',
  },
  controller: OnezakazFormController,
  controllerAs: 'vm',
}
