import { ListController } from '../../controllers/list-controller.class'

/** @ngInject */
class OnozakwinFormController extends ListController {
  constructor($scope, $injector, printer, popup, onozakwinService, $state) {
    super($scope, $injector)
    this.printer = printer
    this.api = onozakwinService
    this.popup = popup
    this.$state = $state
    this.tableOptions = {
      headers: {
        nscheta: '№ счета',
        ufirm: 'Фирма',
        date: 'Дата',
        file: 'Файл',
        cur: 'Вал',
        sum: 'Сумма',
        payed_sum: 'Частичная оплата',
        ost: 'Остаток',
      },
    }
    this.stats = {
      total_acc_sum: 0,
      total_plat_sum: 0,
      total_ost_sum: 0,
    }
  }

  get defaultFilters() {
    const params = this.$state.params
    return { _firm: params._firm || null, _kmfirm: params._kmfirm || '' }
  }

  rowFormatter(row) {
    return row
  }

  updateStats(data) {
    this.api.stats(this.filters).then((stats) => {
      this.stats = stats
    })
  }

  print() {
    this.printer.print('firm_accounts', {
      firm_id: this.filters._firm,
      kmbm: this.filters._kmfirm,
    })
  }
}

export const OnozakwinFormComponent = {
  template: require('./onozakwin-form.component.html'),
  styles: [require('./onozakwin-form.component.scss')],
  bindings: {
    payers: '<',
    ourFirms: '<',
    zaid: '<',
  },
  controller: OnozakwinFormController,
  controllerAs: 'vm',
}
