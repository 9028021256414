import { CRUDService } from './crud-service.class'

export class BalansService extends CRUDService {
  subject = 'balance'

  parse = (p) => {
    p.plan_p = parseInt(p.plan_p)
    p.plan_m = parseInt(p.plan_m)
    p.vist_p = parseInt(p.vist_p)
    p.vist_m = parseInt(p.vist_m)
    p.vist_b = parseInt(p.vist_b)
    p.neop_p = parseInt(p.neop_p)
    p.neop_m = parseInt(p.neop_m)
    p.opl_p = parseInt(p.opl_p)
    p.opl_m = parseInt(p.opl_m)
    p.opl_b = parseInt(p.opl_b)
    return p
  }

  format = (o) => {
    return o
  }
}
