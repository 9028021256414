import { ListController } from '../../controllers/list-controller.class'

/** @ngInject */
class OnoseezakFormController extends ListController {
  constructor($scope, $injector, printer, popup, onoseezakService) {
    super($scope, $injector)
    $scope.vm = this
    this.printer = printer
    this.api = onoseezakService
    this.popup = popup
    this.tableOptions = {
      headers: {
        _full_name: 'Фирма',
      },
    }
  }

  get defaultFilters() {
    return {
      _contains: null,
      inn__contains: null,
    }
  }

  rowFormatter(row) {
    return row
  }

  view() {
    this.popup.state('onozakwin', { _firm: this.selected }, 1024, 768)
  }
}

export const OnoseezakFormComponent = {
  template: require('./onoseezak-form.component.html'),
  styles: [require('./onoseezak-form.component.scss')],
  bindings: {
    firms: '<',
  },
  controller: OnoseezakFormController,
  controllerAs: 'vm',
}
