import { ListController } from '../../controllers/list-controller.class'
import { ourFirms } from '../../states/resolvers'

/** @ngInject */
class DriversListFormController extends ListController {
  ALLOW_DELETE_MSG = 'Удалить карточку водителя?'

  constructor(
    $scope,
    $injector,
    printer,
    popup,
    driverServiceApiV2,
    driverModal,
    $state
  ) {
    super($scope, $injector)
    this.printer = printer
    this.api = driverServiceApiV2
    this.modal = driverModal
    this.modalResolve = {
      ourFirms,
    }
    this.popup = popup
    this.$state = $state
    this.tableOptions = {
      headers: {
        fio: 'ФИО',
      },
    }
  }
}

export const DriversListFormComponent = {
  template: require('./drivers-list-form.component.html'),
  styles: [require('./drivers-list-form.component.scss')],
  bindings: {
    ourFirms: '<',
  },
  controller: DriversListFormController,
}
