import { ngOpenDialog } from '../popups'
import utils from '../utils'

/**
 * Сервис для работы с диалоговыми окнами.
 * @ngInject
 */
export default class PopupService {
  constructor($state, printer) {
    this.$state = $state
    this.printer = printer
  }

  /**
   * Открытие состояния в диалоговом окне.
   *
   * @param {string} matcher URL-матчер
   * @param {object} params параметры состояния
   * @param {number} width длина окна (px)
   * @param {number} height высота окна (px)
   * @returns {Promise<object>} результат выхода из диалога
   */
  state(matcher, params = {}, width = 1024, height = 768) {
    const url = this.$state.href(matcher, params)
    if (url === null) {
      throw new Error(`Invalid state: ${matcher}, ${params}`)
    }
    return ngOpenDialog(url.slice(3), width, height)
  }

  /**
   * Открыть пункт меню.
   */
  openMenuItem(item) {
    const data = item.data
    if (data.sost !== '1') {
      return
    }
    const width = data.width
    const height = data.height
    const [prefix, subject] = data.http.split(':')
    if (prefix === 'state') {
      utils.popups.openState(subject, width, height)
    } else if (prefix === 'print') {
      this.printer.print(subject)
    } else if (prefix === 'print-legacy') {
      this.printer.printLegacy(subject)
    }
  }
}
