import utils from '../utils'
import { CRUDService } from './crud-service.class'

export class NepofirmeService extends CRUDService {
  subject = 'nepofirme'

  parse = (p) => {
    p.data = p.data ? utils.db.dmy2date(p.data) : null
    p.dataplat = p.dataplat ? utils.db.dmy2date(p.dataplat) : null
    return p
  }

  format = (o) => {
    o.data = o.data ? utils.db.date2dmy(o.data) : ''
    o.dataplat = o.dataplat ? utils.db.date2dmy(o.dataplat) : ''
    return o
  }

  zfirms() {
    return this.get({ _action: 'zfirm' })
  }

  /** Суммарная задолженность. */
  totalSum(params = {}) {
    return this.get({ _action: 'total_sum', ...params }).then(
      (resp) => resp.total_sum
    )
  }
}
