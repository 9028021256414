/** @ngInject */
export function busyCursorHttpInterceptor($q) {
  return {
    request: (config) => {
      $('html').addClass('progress')
      return config
    },
    response: (response) => {
      $('html').removeClass('progress')
      return response
    },
    responseError: (rejection) => {
      $('html').removeClass('progress')
      return $q.reject(rejection)
    },
  }
}
