import { CRUDService } from './crud-service.class'

export class OnozakwinService extends CRUDService {
  subject = 'onozakwin'

  parse = (p) => {
    return p
  }

  format = (o) => {
    return o
  }

  payers(params = {}) {
    return this.get({ _action: 'payers', ...params })
  }

  stats(params = {}) {
    return this.get({ _action: 'stats', ...params })
  }
}
