import { CRUDService } from './crud-service.class'

export class OneschetService extends CRUDService {
  subject = 'oneschet'

  parse = (p) => {
    return p
  }

  format = (o) => {
    return o
  }
}
