import { FIRM_CODENAMES_SMALL } from '../../constants'
import { ListController } from '../../controllers/list-controller.class'

class ZaysController extends ListController {
  ALLOW_DELETE_MSG = 'Вы уверены, что хотите удалить заявку?'
  ONLY_AUTHOR_CAN_DELETE = 'Заявку может удалить только ее автор.'

  constructor($scope, $injector, popup, zaysServiceApiV2) {
    super($scope, $injector)
    this.popup = popup
    this.api = zaysServiceApiV2
    this.tableOptions = {
      headers: {
        id: '№',
        type_alias: 'По&nbsp;фирме',
        data: 'Дата',
        plid__comp: 'Плательщик',
        pergruz: 'Груз',
        autor__fio: 'Автор (менеджер)',
      },
      tablesorterOptions: {
        sortList: [[0, 1]],
        headers: {
          2: { sorter: 'dd.mm.yyyy' },
        },
      },
    }
  }

  rowFormatter(row) {
    row.type_alias = FIRM_CODENAMES_SMALL[row.type]
    return row
  }

  get defaultFilters() {
    return {
      pk: null, // номер заявки
      plid_comp: null, // плательщик
      kmbm: null, // по фирме
    }
  }

  add() {
    return $.when(this.popup.state('zay.new', 800, 600)).then(
      this.table.ctrl.addOrUpdateRow
    )
  }

  edit() {
    return $.when(
      this.popup.state('zay.base.detail', { id: this.selected }, 800, 600)
    ).then(this.table.ctrl.addOrUpdateRow)
  }

  finansy() {
    return this.popup.state(
      'zay.base.finansy',
      { id: this.selected },
      1024,
      768
    )
  }

  delete() {
    if (!confirm(this.ALLOW_DELETE_MSG)) {
      return
    }

    return this.api.delete(this.selected).then(this.table.ctrl.removeRow)
  }
}

export const ZaysComponent = {
  template: require('./zays.component.html'),
  styles: [require('./zays.component.scss')],
  bindings: {
    ourFirms: '<',
  },
  controller: ZaysController,
  controllerAs: 'vm',
}
