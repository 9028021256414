// eslint-disable-next-line max-len
import { PrintFormController } from '../../controllers/print-form-controller.class'
import { currentMonth } from '../../utils'

class BalansFormController extends PrintFormController {
  /** @ngInject */
  constructor(
    $q,
    $scope,
    $injector,
    balansService,
    balansNalService,
    balansZ2Service,
    popup
  ) {
    super($scope, $injector)
    this.$q = $q
    this.balansNalService = balansNalService
    this.balansService = balansService
    this.balansZ2Service = balansZ2Service
    this.popup = popup
    this.beznal = {}
    this.nal = {}
    this.itogo = {}
  }

  get defaultFilters() {
    return {
      m1: currentMonth() + 1,
      m2: currentMonth() + 1,
      eksp: null,
      typef: 'plat',
      firm: null,
      opzay: 'excl',
      zaysin: '',
      zkm: 1,
      tm: 1,
      okm: 1,
      th: 1,
      kl: 1,
      bm: 1,
    }
  }

  getBalance() {
    this.$q
      .all([
        this.balansService.get(this.filters),
        this.balansNalService.get(this.filters),
      ])
      .then(([beznal, nal]) => {
        this.beznal = beznal
        this.nal = nal

        this.itogo.aplan_p = this.beznal.plan_p
        this.itogo.aplan_m = this.beznal.plan_m
        this.itogo.avist_p = this.beznal.vist_p + this.nal.nvist_p
        this.itogo.avist_m = this.beznal.vist_m + this.nal.nvist_m
        this.itogo.avist_b = this.beznal.vist_b + this.nal.nvist_b
        this.itogo.aneop_p = this.beznal.neop_p + this.nal.nneop_p
        this.itogo.aneop_m = this.beznal.neop_m + this.nal.nneop_m
        this.itogo.aopl_p = this.beznal.opl_p + this.nal.nopl_p
        this.itogo.aopl_m = this.beznal.opl_m + this.nal.nopl_m
        this.itogo.aopl_b = this.beznal.opl_b + this.nal.nopl_b
        this.itogo.veksp = this.beznal.veksp
      })
  }

  getZays(num) {
    this.balansZ2Service.get({ ...this.filters, type: num }).then((resp) => {
      this.zays = resp
    })
  }

  openZay(id) {
    this.popup.state('zay.base.detail', { id })
  }
}

export const BalansFormComponent = {
  template: require('./balans-form.component.html'),
  styles: [require('./balans-form.component.scss')],
  bindings: {
    ekspeditors: '<',
  },
  controller: BalansFormController,
  controllerAs: 'vm',
}
