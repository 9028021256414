import { printUrl } from '../../popups'

class PlansController {
  /** Таблица перевозок. */
  plansTable = null
  /** Выбранная перевозка. */
  planId = null
  /** Фильтры перевозок. */
  filters = {
    data: new Date(),
    pid: null,
  }

  /** Словарь тягачей (id => тягач). */
  avtos
  /** Словарь наших фирм (код => фирма). */
  ourFirms
  /** Скопированный объект запланированной заявки */
  copiedPlan

  static $inject = [
    '$q',
    '$scope',
    'toaster',
    'planServiceApiV2',
    'addPlanModal',
    'planModal',
    'zaysServiceApiV2',
    'firmServiceApiV2',
    'popup',
  ]

  constructor(
    $q,
    $scope,
    toaster,
    planServiceApiV2,
    addPlanModal,
    planModal,
    zaysServiceApiV2,
    firmServiceApiV2,
    popup
  ) {
    Object.assign(this, {
      $q,
      $scope,
      planServiceApiV2,
      addPlanModal,
      planModal,
      zaysServiceApiV2,
      firmServiceApiV2,
      toaster,
      popup,
    })
    $scope.vm = this

    this.plansTable = {
      options: {
        headers: {
          pid: 'Заявка',
          nomer: 'ТТН',
          data_: 'Дата',
          eksp_name: 'Экспедитор',
          place: 'Адрес погрузки',
          adres: 'Адрес выгрузки',
          kuda: 'Маршрут',
          length: 'Длина (м)',
          width: 'Ширина (м)',
          height: 'Высота (м)',
          weight: 'Вес (т)',
          avto_nomer: 'Тягач',
          driver_fio: 'Водитель',
          pricep_nomer: 'Прицеп',
        },
      },
      init$: $q.defer(),
      service: planServiceApiV2,
      ctrl: null,
      rowfmt: (row) => {
        row.data_ = row.data
          ? $.fn.dateutils.date2str_ddmmyyyy(row.data, '.')
          : '-'
        row.eksp_name = row.eksp ? row.eksp_info.name : '-'
        row.avto_nomer = row.avto ? row.avto_info.nomer : '-'
        row.pricep_nomer = row.pricep ? row.pricep_info.nomer : '-'
        row.driver_fio = row.driver ? row.driver_info.fio : '-'
        return row
      },
    }
  }

  /** Выбранная перевозка. */
  get plan() {
    return this.plansTable.ctrl.selectedObject
  }

  /** Инициализация компонента. */
  $onInit() {
    this.$q.all([this.plansTable.init$.promise]).then((resps) => {
      this.plansTable.ctrl = resps[0]
    })
  }

  /** Сбросить фильтры. */
  resetFilters() {
    Object.assign(this.filters, { pid: null, data: new Date() })
  }

  /** Запланировать перевозку (с последующим редактированием). */
  add() {
    return this._openAddPlanModal().closed.then((planGruz) => {
      if (planGruz) {
        this.plansTable.ctrl.addOrUpdateRow(planGruz)
        this.edit()
      }
    })
  }

  /** Запланировать перевозку на основе выбранной. */
  addFromSelected() {
    const plan2 = Object.assign({}, this.plan)

    delete plan2.id

    return this._openAddPlanModal(plan2).closed.then((planGruz) => {
      if (planGruz) {
        this.plansTable.ctrl.addOrUpdateRow(planGruz)
        this.edit()
      }
    })
  }

  /** Редактировать выбранную или указанную перевозку. */
  edit(plan = null) {
    if (!plan) {
      plan = this.plan
    }
    const modal = this._openPlanModal(plan)
    return modal.closed.then(this.plansTable.ctrl.addOrUpdateRow)
  }

  /** Скопировать план в буфер */
  copyPlan() {
    const selected = JSON.parse(JSON.stringify(this.plan))

    delete selected.id
    delete selected.pid
    delete selected.nomer
    delete selected.data

    this.copiedPlan = selected

    this.toaster.pop({
      type: 'success',
      title: 'Скопировано в буфер обмена',
    })
  }

  /** Вставить план из буфера */
  pastePlan() {
    const plan = Object.assign({ ...this.plan }, this.copiedPlan)
    this.edit(plan)
  }

  /** Открыть модальное окно "Запланировать перевозку". */
  _openAddPlanModal(sourcePlan) {
    const modal = this.addPlanModal.open({
      locals: {
        data: this.filters.data,
        ekspeditors: this.ekspeditors,
        sourcePlan,
      },
    })
    return modal
  }

  /** Открыть модальное окно формы перевозки. */
  _openPlanModal(plan) {
    const modal = this.planModal.open({
      locals: {
        plan,
        copiedPlan: this.copiedPlan,
        avtos: this.avtos,
        ourFirms: this.ourFirms,
      },
      resolve: {
        zay: () => this.zaysServiceApiV2.get({ id: plan.pid }),
        zayClient: (zay) =>
          zay.then((zay) =>
            zay.zaid ? this.firmServiceApiV2.get({ id: zay.zaid }) : null
          ),
      },
    })
    return modal
  }

  /** Удалить перевозку. */
  remove() {
    if (!confirm('Удалить план перевозки?')) {
      return
    }
    this.planServiceApiV2.delete(this.planId).then(() => {
      this.plansTable.ctrl.removeRow(this.planId)
    })
  }

  /** Печать списка. */
  printSpisok() {
    const d = this.filters.data
    // eslint-disable-next-line no-undef
    printUrl(
      'print2.php?path=plan_spisok.php&_day=' +
        d.getDate() +
        '&_mesac=' +
        (d.getMonth() + 1) +
        '&_year=' +
        d.getFullYear()
    )
  }

  openPlanPer() {
    this.popup.state('planper', {}, 480, 300)
  }

  close() {
    window.close()
  }

  onZayChange() {
    this.filters.data = null // [ORG-130]
  }
}

export const PlansComponent = {
  template: require('./plans.component.html'),
  styles: [require('./plans.component.scss')],
  bindings: {
    avtos: '<',
    ourFirms: '<',
    ekspeditors: '<',
  },
  controller: PlansController,
}
