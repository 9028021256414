import { ListController } from '../../controllers/list-controller.class'
import { tachographs } from '../../states/resolvers'

class AllavtoFormController extends ListController {
  ALLOW_DELETE_MSG = 'Вы уверены, что хотите удалить тягач?'

  constructor(
    $q,
    $scope,
    $timeout,
    $stateParams,
    $injector,
    avtoServiceApiV2,
    avtoModal,
    printer
  ) {
    super($scope, $injector)
    this.$q = $q
    this.$scope = $scope
    this.$timeout = $timeout
    this.$stateParams = $stateParams
    this.api = avtoServiceApiV2
    this.modal = avtoModal
    this.modalResolve = { tachographs }
    this.printer = printer
    $scope.vm = this
    this.tableOptions = {
      headers: {
        mark: 'Марка',
        nomer: 'Номер&nbsp;тягача',
        bnorm: 'Баз. норма',
        ntr: 'норма т.раб',
        type: 'Тип А Г Л',
        fuel: 'Топл Б Д',
        volume: 'V бака',
        notop: 'Норма с отопит.',
        vnomer: 'Вн №',
        polu: 'Закрепленный полуприцеп',
        driver: 'Закрепленный водитель',
        weight: 'Вес тягача',
        tahinfo: 'Тахометр',
      },
    }
    this.selected = $stateParams.selavto || null
  }

  rowFormatter(row) {
    row.tahinfo = row.tachograph_info ? row.tachograph_info.full_name : ''
    return row
  }

  get defaultFilters() {
    return {
      pid: +this.$stateParams.pid || null,
    }
  }

  print() {
    this.printer.print('auto1', { fid: this.filters.pid })
  }
}

export const AllavtoFormComponent = {
  template: require('./allavto-form.component.html'),
  styles: [require('./allavto-form.component.scss')],
  bindings: {
    avtos: '<',
    firm: '<', // опционально
    ourFirms: '<',
    // tachographs: '<',
  },
  controller: AllavtoFormController,
  controllerAs: 'vm',
}
