import { settings } from '../../app.config'
import { ListController } from '../../controllers/list-controller.class'
import utils from '../../utils'

/** @ngInject */
class NepofirmeFormController extends ListController {
  constructor(
    $scope,
    $injector,
    printer,
    nepofirmeService,
    popup,
    zatratiService
  ) {
    super($scope, $injector)
    $scope.vm = this
    this.printer = printer
    this.api = nepofirmeService
    this.zatratiService = zatratiService
    this.popup = popup
    this.tableOptions = {
      headers: {
        nomer: '№ счета',
        data_: 'Дата',
        pid: 'Заявка',
        summa: 'Задолженность',
        plat: 'Платежка',
        dataplat_: 'Дата платежки',
      },
    }
    this.model = null
  }

  get defaultFilters() {
    return {
      firm: null,
      optype: '2',
    }
  }

  rowFormatter(row) {
    row.data_ = row.data ? utils.db.date2dmy(row.data) : ''
    row.dataplat_ = row.dataplat ? utils.db.date2dmy(row.dataplat) : ''
    return row
  }

  print() {
    this.printer.print('nepofirme', {
      year: settings.defaultYear,
      ...this.filters,
    })
  }

  onSelect() {
    this.model = { ...this.selectedData }
    this.api
      .totalSum({ firm: this.selectedData.firms })
      .then((totalSum) => (this.totalSum = totalSum))
  }

  save() {
    this.zatratiService
      .get({ id: this.model.id })
      .then((model) => {
        model.plat = this.model.plat
        model.dataplat = this.model.dataplat
        return this.zatratiService.save(model)
      })
      .then(this.table.ctrl.updateRow)
  }
}

export const NepofirmeFormComponent = {
  template: require('./nepofirme-form.component.html'),
  styles: [require('./nepofirme-form.component.scss')],
  bindings: {
    zfirms: '<',
    totalSum: '<',
  },
  controller: NepofirmeFormController,
  controllerAs: 'vm',
}
