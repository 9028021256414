window.__env = window.__env || {
  API_URL: '',
  API1_URL: '',
}

export const settings = {
  /** Ссылка на APIv2. */
  apiUrl: window.__env.API_URL,
  /**
   * Ссылка на APIv1.
   * @deprecated переходим на APIv2
   * */
  apiV1Url: window.__env.API1_URL,
  /** Среда (dev, prod, local). */
  environment: ENVIRONMENT,
  /** Настройки Sentry. */
  sentry: {
    dsn: SENTRY_DSN,
    tracesSampleRate: SENTRY_TRACES_SAMPLE_RATE,
  },
  /** Версия программы. */
  version: VERSION,
  keycloak: {
    serverUrl: window.__env.KEYCLOAK_URL || 'http://localhost:8080',
    realm: window.__env.KEYCLOAK_REALM || 'keenmark-realm',
    clientId: window.__env.KEYCLOAK_CLIENT_ID || 'gruz-client',
  },
  frontend: {
    /** Задержка изменения модели. */
    debounce: 1000,
  },
}
/** Отчетный год. */
Object.defineProperty(settings, 'defaultYear', {
  get: () => {
    return parseInt(window.__env.DEFAULT_YEAR || new Date().getFullYear())
  },
})
console.log(settings)
