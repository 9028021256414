/** @ngInject */
class MenuRowController {
  constructor($scope, $state, currentUser, popup) {
    $scope.vm = this
    this.$state = $state
    this.active = null
    this.currentUser = currentUser
    this.popup = popup
  }

  $onInit() {
    this.items = this.items.filter((item) =>
      this.currentUser.hasMenuItemAccess(item)
    )
    this.extraItems = this.extraItems.filter((item) =>
      this.currentUser.hasMenuItemAccess(item)
    )
  }

  openItem(item) {
    this.popup.openMenuItem(item)
  }
}

export const MenuRowComponent = {
  template: require('./menu-row.component.html'),
  styles: [require('./menu-row.component.scss')],
  bindings: {
    items: '<',
    extraItems: '<',
    active: '<',
    userProfile: '<',
    onLogout: '&',
  },
  controller: MenuRowController,
}
