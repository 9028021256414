require('jquery-ui/themes/base/all.css')
require('jquery-ui/ui/widgets/datepicker')

export function DatePickerDirective($timeout) {
  return {
    restrict: 'A',
    link,
    require: '?ngModel',
    scope: {
      onSelect: '&',
    },
  }

  function link(scope, el, attrs, ngModel) {
    const $el = $(el[0])

    if (ngModel) {
      ngModel.$render = $render
    }

    initDatePicker()

    function initDatePicker() {
      $el.datepicker({
        dateFormat: 'd.mm.yy',
        changeMonth: true,
        changeYear: true,
        onSelect: function (dateStr) {
          $timeout(function () {
            onSelect(dateStr)
          })
        },
      })
    }

    function $render() {
      const timestamp = ngModel.$modelValue

      if (!timestamp) {
        $el.val(null)
      } else {
        const date = new Date(timestamp * 1000)
        $el.datepicker('setDate', date)
      }
    }

    function onSelect(dateStr) {
      if (!scope.onSelect) {
        return
      }

      const ts = !dateStr ? null : str2date(dateStr)
      scope.onSelect({ date: ts })
    }
  }
}

// function date2str (timestamp) {
//   if (timestamp === null) {
//     return null
//   }
//   const d = new Date(timestamp * 1000)
//   return d.getDate() + '.' + (d.getMonth() + 1) + '.' +
//     d.getFullYear()
// }

function str2date(dateStr) {
  if (dateStr === null) {
    return null
  }
  const parts = dateStr.split('.')
  if (parts.length !== 3) {
    throw new Error('Wrong date format')
  }
  const isoDateStr = parts.reverse().join('/')
  return new Date(isoDateStr).getTime() / 1000
}
