import { ListController } from '../../controllers/list-controller.class'

/** @ngInject */
class OneschetFormController extends ListController {
  constructor($scope, $injector, printer, popup, oneschetService, $state) {
    super($scope, $injector)
    this.printer = printer
    this.api = oneschetService
    this.popup = popup
    this.$state = $state
    this.tableOptions = {
      headers: {
        pid: 'Заказ',
        nomer: 'ТТН',
        eksp: 'Экспедитор',
      },
    }
    this.stats = {
      total_acc_sum: 0,
      total_plat_sum: 0,
      total_ost_sum: 0,
    }
  }

  get defaultFilters() {
    const params = this.$state.params
    return { _zid: params._zid || null, _man: null }
  }

  rowFormatter(row) {
    return row
  }

  updateStats(data) {}
}

export const OneschetFormComponent = {
  template: require('./oneschet-form.component.html'),
  styles: [require('./oneschet-form.component.scss')],
  bindings: {
    ekspeditors: '<',
    zaid: '<',
  },
  controller: OneschetFormController,
  controllerAs: 'vm',
}
