import { settings } from '../../app.config'
import utils from '../../utils'

/** @ngInject */
export function TachsController($scope, $http, $timeout, popup) {
  const vm = this
  $scope.vm = vm

  Object.assign(vm, {
    selected: null,
    tachs: $('#tachs'),
    init: () => {
      vm.tachs
        .jsTable({
          headers: {
            mark: 'Марка',
            type: 'Тип',
            serial: 'Серийный номер',
            valid_to: 'Действителен по',
          },
          sourceUrl: `${settings.apiV1Url}/api.php?url=tachs`,
          tablesorterOptions: {
            sortList: [
              [0, 0],
              [1, 0],
              [2, 0],
            ],
          },
        })
        .jsTable('loadRows', [])
        .jsTable('option', 'onRowClick', () => {
          $timeout(() => {
            vm.selected = vm.tachs.jsTable('getSelectedRowData')
          })
        })
        .jsTable('option', 'onRowDblClick', () => {
          $timeout(vm.edit)
        })

      vm.reload()
    },
    add: () => {
      $.when(popup.state('tach.new', {}, 400, 300)).then((tach) => {
        vm.selected = tach
        return vm.reload().then(() => {
          vm.tachs.jsTable('selectRowById', tach.id)
        })
      })
    },
    edit: () => {
      $.when(
        popup.state('tach.base.edit', { id: vm.selected.id }, 400, 300)
      ).then((tach) => {
        vm.selected = tach
        return vm.reload().then(() => {
          vm.tachs.jsTable('selectRowById', tach.id)
        })
      })
    },
    delete: () => {
      if (
        !confirm(
          'Вы уверены, что хотите удалить информацию о выбранном тахографе?'
        )
      ) {
        return
      }

      $http
        .delete(`${settings.apiV1Url}/api.php?url=tachs&id=${vm.selected.id}`)
        .then(() => {
          vm.selected = null
          return vm.reload()
        })
    },
    close: () => {
      window.close()
    },
    reload: () => {
      return $http
        .get(`${settings.apiV1Url}/api.php?url=tachs`)
        .then((resp) => {
          resp.data.forEach((t) => {
            if (t.valid_to) {
              const db = utils.db
              t.valid_to = db.date2dmy(db.str2date(t.valid_to))
            }
          })
          vm.tachs.jsTable('loadRows', resp.data)
        })
    },
  })

  vm.init()
}

export const TachographsComponent = {
  template: require('./tachographs.component.html'),
  styles: [require('./tachographs.component.scss')],
  bindings: {},
  controller: TachsController,
}
