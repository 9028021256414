import PopeyeModal from '../popeye-modal.class'

export default class DriverModal extends PopeyeModal {
  modal = {
    template: require('./driver.modal.html'),
    /** @ngInject */
    controller: function ($scope, item, ourFirms) {
      $scope.item = item
      $scope.ourFirms = ourFirms
    },
  }
}
