import { settings } from '../../app.config'
import { ListController } from '../../controllers/list-controller.class'
import utils from '../../utils'

/** @ngInject */
class PergruziFormController extends ListController {
  constructor($scope, $injector, printer, pergruziService, popup) {
    super($scope, $injector)
    $scope.vm = this
    this.printer = printer
    this.api = pergruziService
    this.popup = popup
    this.tableOptions = {
      headers: {
        gruz: 'Груз',
        length: 'Длина',
        width: 'Ширина',
        height: 'Высота',
        weight: 'Вес',
        route: 'Маршрут',
        date_: 'Дата',
        zay: 'Заявка',
        client: 'Заказчик',
      },
    }
  }

  get defaultFilters() {
    return {
      _gruz_length_from: null,
      _gruz_width_from: null,
      _gruz_height_from: null,
      _gruz_weight_from: null,
      _gruz_length_to: null,
      _gruz_width_to: null,
      _gruz_height_to: null,
      _gruz_weight_to: null,
      _sel_plat: null,
    }
  }

  rowFormatter(row) {
    row.date_ = utils.db.date2dmy(row.date)
    return row
  }

  edit() {
    const plan = this.selectedData
    this.popup.state(
      'zay.base.ttn.detail',
      { id: plan.zay_id, nomer: plan.ttn_nomer },
      800,
      600
    )
  }

  print() {
    this.printer.print('pergruzi', {
      year: settings.defaultYear,
      ...this.filters,
    })
  }
}

export const PergruziFormComponent = {
  template: require('./pergruzi-form.component.html'),
  styles: [require('./pergruzi-form.component.scss')],
  bindings: {
    ekspeditors: '<',
  },
  controller: PergruziFormController,
  controllerAs: 'vm',
}
