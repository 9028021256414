import utils from '../utils'
import { CRUDService } from './crud-service.class'

export class PergruziService extends CRUDService {
  subject = 'pergruzi'

  parse = (p) => {
    p.date = p.date ? utils.db.str2date(p.date) : null
    return p
  }

  format = (o) => {
    o.date = o.date ? utils.db.date2str(o.date) : null
    return o
  }
}
