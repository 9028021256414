/**
 * Сервис для получения данных о текущем пользователе.
 * @ngInject
 */
export class CurrentUserService {
  constructor($http, keycloakService) {
    this.$http = $http
    this.keycloakService = keycloakService
  }

  /**
   * Роли пользователя.
   * @returns {Array<string>} роли пользователя
   */
  get roles() {
    return this.keycloakService.combinedRoles
  }

  get email() {
    return this.keycloakService.tokenParsed?.email
  }

  /**
   * Пользователь имеет одну из ролей.
   * @param {string|Array<string>} roles роли
   * @returns {boolean}
   */
  hasRole(roles) {
    if (typeof roles === 'string') {
      roles = [roles]
    }
    return roles.find((r) => this.roles.includes(r)) !== undefined
  }

  /**
   * Имеет ли пользователь доступ к пункту меню.
   * @param {MenuItem} item пункт меню
   * @returns {boolean}
   */
  hasMenuItemAccess(item) {
    // если нет словаря с ролями, то пункт доступен всем
    if (!('roles' in item.data)) {
      return true
    }

    const hasAccess = this.hasRole(item.data.roles)
    return hasAccess
  }

  /**
   * Получение профиля пользователя
   * @returns {Promise<User>}
   */
  getProfile() {
    return this.keycloakService.instance.loadUserInfo()
  }
}
