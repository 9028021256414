export class AuthHttpInterceptorService {
  /** @ngInject */
  constructor(keycloakService, toaster) {
    this.keycloakService = keycloakService
    this.toaster = toaster
  }

  /**
   * Перехват запроса.
   * @param {*} config конфигурация запроса
   * @returns {*} конфигурация запроса
   */
  request = (config) => {
    config.headers = config.headers || {}
    const token = this.keycloakService.token
    if (token) {
      return this.keycloakService
        .updateToken()
        .then((refreshed) => {
          config.headers.Authorization = `Bearer ${token}`
          return config
        })
        .catch(() => {
          this.toaster.pop({
            type: 'error',
            title: 'Ошибка доступа',
            body: 'Не удалось обновить токен доступа или истекло время сессии',
          })
          return config
        })
    }
    return config
  }
}
