import Keycloak from 'keycloak-js'
import { settings } from '../app.config'

export class KeycloakService {
  /** @type Keycloak */
  _keycloak = null
  /** @type Keycloak.KeycloakProfile */
  _userProfile = null
  _userInfo = null
  _loadProfileAtStartup = true

  constructor(keycloak) {
    this._keycloak = keycloak
  }

  /** Эксземпляр Keycloak */
  get instance() {
    return this._keycloak
  }

  get authenticated() {
    if (!this._keycloak) return false
    return this._keycloak.authenticated
  }

  get token() {
    return this.getToken()
  }

  get tokenParsed() {
    return this._keycloak.tokenParsed
  }

  get userInfo() {
    return this._userInfo
  }

  get userProfile() {
    return this._userProfile
  }

  get realmRoles() {
    return this._keycloak.realmAccess?.roles ?? []
  }

  get resourceRoles() {
    const clientId = settings.keycloak.clientId
    return this._keycloak.resourceAccess?.[clientId]?.roles ?? []
  }

  /**
   * Роли домена и роли клиента в одном списке.
   * @returns {Array<string>}
   * */
  get combinedRoles() {
    return this.realmRoles.concat(this.resourceRoles)
  }

  /** @deprecated */
  getToken() {
    return this._keycloak.token
  }

  updateToken() {
    return this._keycloak.updateToken()
  }

  init() {
    this._keycloak = new Keycloak({
      url: `${settings.keycloak.serverUrl}`,
      realm: settings.keycloak.realm,
      clientId: settings.keycloak.clientId,
    })

    return this._keycloak.init({
      enableLogging: true,
      onLoad: 'login-required',
      // onLoad: 'check-sso',
      // silentCheckSsoRedirectUri:
      //   window.location.origin + '/assets/silent-check-sso.html',
      flow: 'standard',
      pkceMethod: 'S256',
    })
  }

  login(options = {}) {
    return this._keycloak.login(options)
  }

  logout(options = {}) {
    return this._keycloak.logout(options)
  }
}
