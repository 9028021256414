/** Наши фирмы */
export function ourFirms(firmServiceApiV2) {
  return firmServiceApiV2.getOurFirms()
}

export function legacyUsers(legacyUsersServiceApiV2) {
  return legacyUsersServiceApiV2.get()
}

export function managers(ekspServiceApiV2) {
  return ekspServiceApiV2.get()
}

/** Все экспедиторы. */
export function ekspeditors(ekspServiceApiV2) {
  return ekspServiceApiV2.get()
}

/** Все тарифы. */
export function tarifi(tarifiServiceApiV2) {
  return tarifiServiceApiV2.get()
}

/** Счет по id. */
export function uchetById($stateParams, uchetServiceApiV2) {
  return uchetServiceApiV2.get({ id: $stateParams.id })
}

/** Опциональный заказчик */
export function optFirm(key) {
  return ($stateParams, firmServiceApiV2) => {
    const id = $stateParams[key]
    if (!id) return null

    return firmServiceApiV2.get({ id })
  }
}

export function avtos(avtoServiceApiV2) {
  return avtoServiceApiV2.get({})
}

export function tachographs(tachographService) {
  return tachographService.get({})
}
