import { CRUDService } from './crud-service.class'

export class UchetPechatService extends CRUDService {
  subject = 'uchet-pechat'

  parse = (up) => {
    up.id = 1 // id-шника на самом деле нет
    up.bank = parseInt(up.bank)
    up.datap = $.fn.dateutils.str_yyyymmdd2date(up.datap, '-')
    up.datapa = $.fn.dateutils.str_yyyymmdd2date(up.datapa, '-')
    up.datapf = $.fn.dateutils.str_yyyymmdd2date(up.datapf, '-')
    up.schet = parseInt(up.schet)
    up.type = parseInt(up.type)
    up.vozvract = parseInt(up.vozvract)
    up.values.forEach((v, idx) => {
      v.id = idx + 1
      v.count = parseInt(v.count)
      v.summ = parseFloat(v.summ.replace(',', '.'))
      v.voz = parseInt(v.voz)
      v.num = parseInt(v.num)
      if (v.nds !== '') {
        v.nds = parseInt(v.nds.split('%')[0])
      }
    })
    return up
  }

  format = (o) => {
    if (o.datap) {
      o.datap = $.fn.dateutils.date2str_yyyymmdd(o.datap, '-')
    }
    o.datapa = $.fn.dateutils.date2str_yyyymmdd(o.datapa, '-')
    o.datapf = $.fn.dateutils.date2str_yyyymmdd(o.datapf, '-')

    fixValues(o.values)

    // учет-печать использует двойной ключ, а не id
    delete o.id

    return o

    function augmentTo(values, n) {
      const nvals = values.length
      $.fn.assert(n >= nvals, 'Неверный аргумент')

      for (const idx of $.fn.range(nvals, n - nvals)) {
        values.push({
          count: '',
          nds: '',
          izm: '',
          summ: '',
          text: '',
          num: idx + 1,
        })
      }

      $.fn.assert(values.length === n)
    }

    function fixValues(values) {
      // дополнение до 10 строк ("вознаграждение" должно быть на 10-й строке)
      o.values.sort((a, b) => a.num - b.num)
      if (o.values.find((v) => v.num === 10) !== undefined) {
        const v10 = o.values.pop()
        augmentTo(o.values, 9)
        o.values.push(v10)
      } else {
        augmentTo(o.values, 10)
      }

      // поправим данные
      values.forEach((v, idx) => {
        // перенумеруем
        v.num = idx + 1

        // исправим НДС
        if (
          !isNaN(v.nds) &&
          v.nds !== '' &&
          v.nds !== null &&
          v.nds !== undefined
        ) {
          v.nds = '' + v.nds + '%'
        } else {
          v.nds = ''
        }

        // удалим id строки (они нужны только для таблицы)
        delete v.id
      })

      $.fn.assert(o.values.length === 10, 'должно быть 10 строк')
      $.fn.assert(
        JSON.stringify(Array.from($.fn.range(1, 10))) ===
          JSON.stringify(o.values.map((v) => v.num).sort((a, b) => a - b)),
        'Неправильная нумерация'
      )

      return values
    }
  }

  save(obj) {
    // deep copy
    const data = _.cloneDeep(obj)

    const parsed = this.format(data)
    parsed.values.forEach((p) => {
      if (!p.count) {
        p.count = ''
      }
      if (!p.summ) {
        p.summ = ''
      }
    })
    return this.$http
      .post(`${this.baseUrl}/api.php?url=${this.subject}`, parsed)
      .then((resp) => {
        return this.parse(resp.data)
      })
  }

  delete(id) {}
}
