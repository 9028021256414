/** @ngInject */
export function toasterHttpInterceptor($q, toaster) {
  const excludedUrl = (url) => {
    return /auth\/jwt/.test(url)
  }

  return {
    request: (config) => {
      return config
    },
    response: (response) => {
      const config = response.config

      if (excludedUrl(config.url)) {
        return response
      }

      if (config.method === 'POST' && response.status === 200) {
        toaster.pop({ body: 'Изменения сохранены.' })
      }
      return response
    },
    responseError: (rejection) => {
      const config = rejection.config

      if (rejection.status === 401) {
        return $q.reject(rejection)
      }

      if (rejection.status === 403) {
        toaster.pop({
          type: 'error',
          title: 'Ошибка доступа',
          body:
            rejection.data.detail ||
            'Недостаточно прав для совершения действия',
        })
      } else if (['POST', 'PUT'].includes(config.method)) {
        toaster.pop({ type: 'error', body: 'Ошибка сохранения.' })
      } else {
        toaster.pop({ type: 'error', body: 'Ошибка запроса.' })
      }
      return $q.reject(rejection)
    },
  }
}
