if (!$.jsTable) {
  ;(function ($) {
    require('jquery-ui/ui/widget')
    require('./jquery.tablesorter')

    // firefox
    if (!Element.prototype.scrollIntoViewIfNeeded) {
      Element.prototype.scrollIntoViewIfNeeded = function (
        centerIfNeeded = true
      ) {
        const el = this
        new IntersectionObserver(function ([entry]) {
          const ratio = entry.intersectionRatio
          if (ratio < 1) {
            const place = ratio <= 0 && centerIfNeeded ? 'center' : 'nearest'
            el.scrollIntoView({
              block: place,
              inline: place,
            })
          }
          this.disconnect()
        }).observe(this)
      }
    }

    $.widget('km.jsTable', {
      tableElement: null,

      options: {
        headers: {},
        tablesorterOptions: null,
        stickyHeaders: true,
        thead: null,
        tbody: null,
        container: null,
        onRowClick: function (event) {},
        onRowDblClick: function (event) {},
        sourceUrl: '',
        trackSelection: false,
        trackSorting: true,
        rowRenderer: function (row) {
          return row
        },
      },

      _create: function () {
        if (this.element[0].nodeName === 'DIV') {
          this.tableElement = $(
            '<table class="tablesorter full-width"></table>'
          )
          this.element.append(this.tableElement)
          this.element.addClass('js-table-container')
        } else {
          this.tableElement = this.element
        }

        if (!this.options.container)
          this.options.container = this.tableElement[0].parentElement

        Object.defineProperty(this.options, 'data', {
          get: function () {
            if (!this._data) {
              this._data = {}
            }
            return this._data
          },
        })

        this.options.thead = this.tableElement.find('thead')[0]
        if (!this.options.thead) {
          this.tableElement.append($('<thead></thead>'))
          this.options.thead = this.tableElement.find('thead')[0]
        }

        this.options.tbody = this.tableElement.find('tbody')[0]
        if (!this.options.tbody) {
          this.tableElement.append($('<tbody></tbody>'))
          this.options.tbody = this.tableElement.find('tbody')[0]
        }

        $(this.options.container).scroll(this._onScroll.bind(this))

        this.tableElement.addClass('js-table')
        this._initHeaders(this.options.headers)
        this._initDefaultRow(this.options.headers)
        this._initRows()
        this._loadSavedRow()
        this.tableElement.tablesorter(
          Object.assign(
            {
              dateFormat: 'dd.mm.yy',
            },
            this.options.tablesorterOptions
          )
        )

        // автоматическая сортировка ячеек после изменения
        this.tableElement.on('updateEnd', () => {
          if (!this.options.trackSorting) {
            return
          }
          const sortList = this.tableElement[0].config.sortList
          if (sortList.length > 0 && this.options.tbody.childNodes.length > 0) {
            this.tableElement.trigger('sorton', [sortList])
          }
        })
      },

      _setOption: function (key, value) {
        this.options[key] = value
      },

      // TODO сюда также можно перенести фильтры, чтобы была авто-поддержка
      // функциональности типа reload and select next/prev
      reload: function () {
        return this.ajaxRows(this.options.sourceUrl)
      },

      getRows: function () {
        return this.options.tbody.childNodes
      },

      getRowData: function (row) {
        const id = $(row).attr('row-id')
        return Object.assign({}, this.options.data[id])
      },

      getData: function () {
        return this.options.data
      },

      addRow: function (row) {
        const tbody = $(this.options.tbody)
        const idx = tbody.find('tr').length
        const id = row.id

        const tr = $('<tr tabindex="' + idx + '" row-id="' + id + '">')
        this.options.data[id] = row
        tbody.append(tr)
        this.updateRowById(id, row)
        this._initRow(tr)
        return row
      },

      updateRowById: function (id, row) {
        const $tr = $(this.getRowById(id))
        $tr.empty()
        this.options.data[id] = row
        $tr.append($(this._renderTDs(row)))
        this.tableElement.trigger('update')
        return row
      },

      removeRowById: function (id) {
        const $tr = $(this.getRowById(id))
        const $prev = this.getPrev(1)
        const $next = this.getNext(1)
        $tr.remove()
        delete this.options.data[id]
        this.tableElement.trigger('update')

        if (this.options.trackSelection) {
          const prevId = $prev.attr('row-id')
          // eslint-disable-next-line eqeqeq
          if ($prev && prevId != id) {
            $prev.click()
          } else if ($next) {
            $next.click()
          }
        }
      },

      loadRows: function (rows) {
        if (!$.isArray(rows)) rows = []

        const that = this
        const tbody = $(this.options.tbody)
        $.tablesorter.clearTableBody(this.tableElement[0])

        // clear data
        for (const id of Object.keys(this.options.data)) {
          delete this.options.data[id]
        }

        let rowsHtml = ''
        $.each(rows, function (id, row) {
          const rowId = row.id
          that.options.data[rowId] = row

          // eslint-disable-next-line max-len
          rowsHtml += `<tr tabindex="${id}" row-id="${rowId}">${that._renderTDs(row)}</tr>`
        })

        tbody.append($(rowsHtml))

        this._initRows()
        this.tableElement.trigger('update')
      },

      _renderTDs: function (data) {
        const rendered = this.options.rowRenderer(Object.assign({}, data))

        let html = ''
        for (const hkey in this.options.headers) {
          // eslint-disable-next-line no-prototype-builtins
          if (!this.options.headers.hasOwnProperty(hkey)) continue
          const value = rendered[hkey]
          const strvalue = value !== null && value !== undefined ? value : ''
          html += '<td>' + strvalue + '</td>'
        }
        return html
      },

      _initHeaders: function (headers) {
        const thead = $(this.options.thead)
        thead.empty()
        const tr = $('<tr>')
        thead.append(tr)
        for (const id in headers) {
          // eslint-disable-next-line no-prototype-builtins
          if (!headers.hasOwnProperty(id)) continue

          const headerOptions = headers[id]
          let header = {
            title: headerOptions,
          }
          // eslint-disable-next-line eqeqeq
          if ($.type(headerOptions) != 'string') {
            header = headerOptions
          }

          tr.append(
            $(
              '<th width=' +
                header.width +
                ' class=' +
                header.class +
                '>' +
                '<div>' +
                header.title +
                '</div>' +
                header.title +
                '</th>'
            )
          )
        }
      },

      _initDefaultRow: function (headers) {
        const tbody = $(this.options.tbody)
        const tr = $('<tr style="display:none">')
        for (const id in headers) {
          // eslint-disable-next-line no-prototype-builtins
          if (!headers.hasOwnProperty(id)) continue
          tr.append($('<td></td>'))
        }
        tbody.append(tr)
      },

      // TODO: сделать 1 большой reinitialize
      _initRows: function () {
        const rows = this.options.tbody.childNodes
        $(rows).click(this._onRowClick.bind(this))
        $(rows).dblclick(this._onRowDblClick.bind(this))
        $(rows).keydown(this._onRowKeyDown.bind(this))
        $(rows).keyup(this._onRowKeyUp.bind(this))
      },

      _initRow: function (tr) {
        $(tr).click(this._onRowClick.bind(this))
        $(tr).dblclick(this._onRowDblClick.bind(this))
        $(tr).keydown(this._onRowKeyDown.bind(this))
        $(tr).keyup(this._onRowKeyUp.bind(this))
      },

      ajaxRows: function (url, success) {
        const that = this
        this.options.sourceUrl = url + ''
        return $.ajax({
          url,
          success: function (rows) {
            if (!$.isArray(rows)) {
              rows = arrayValues(rows)
            }
            that.loadRows(rows)
            return success ? success(rows) : $.when(rows)
          },
        })

        function arrayValues(input) {
          const tmpArr = []
          let key = ''
          for (key in input) {
            tmpArr[tmpArr.length] = input[key]
          }
          return tmpArr
        }
      },

      focusRow: function (row) {
        const oldRow = this.getFocusedRow()
        if (oldRow) {
          $(oldRow).removeClass('highlight')
        }
        if (row) {
          $(row).addClass('highlight')
        }
        row.scrollIntoViewIfNeeded(false)
      },

      selectRow: function (row) {
        const oldRow = this.getSelectedRow()
        if (oldRow) {
          $(oldRow).removeClass('selected')
        }
        $(row).addClass('selected')
        this._saveRow(row)

        const focusedRow = this.getFocusedRow()
        if (focusedRow) $(focusedRow).removeClass('highlight')
        row.scrollIntoViewIfNeeded(false)
      },

      selectRowByIndex: function (index) {
        const row = this.options.tbody.childNodes[index]
        if (!row) {
          return null
        }
        this.selectRow(row)
        return row
      },

      getRowById: function (id) {
        const row = this.tableElement.find('tr[row-id="' + id + '"]')[0]
        return undef2null(row)
      },

      selectRowById: function (id) {
        const row = this.getRowById(id)
        if (!row) {
          return null
        }
        this.selectRow(row)
        return row
      },

      getSelectedRow: function () {
        const row = this.tableElement.find('tr.selected')[0]
        return undef2null(row)
      },

      getSelectedRowData: function () {
        const row = this.getSelectedRow()
        let data = null
        if (row) {
          data = this.getRowData(row)
        }
        return data
      },

      getFocusedRow: function () {
        const highlighted = this.tableElement.find('tr.highlight')[0]
        return undef2null(highlighted)
      },

      clearSelection: function () {
        const row = this.getSelectedRow()
        if (row) $(row).removeClass('selected')
      },

      _saveRow: function (row) {
        // сохраним в хранилище выбранный элемент
        const id = this.tableElement.attr('id')
        const rowIndex = $(row).attr('tabindex')
        localStorage.setItem(id + '_selected_row_tabindex', rowIndex)
      },

      _loadSavedRow: function () {
        const id = this.tableElement.attr('id')
        const rowIndex = localStorage.getItem(id + '_selected_row_tabindex')
        if (!rowIndex) return

        const row = this.tableElement.find('tr[tabindex=' + rowIndex + ']')[0]
        if (!row) return

        // eslint-disable-next-line no-undef
        selectRow(row)
      },

      _onScroll: function (event) {
        if (!this.options.stickyHeaders) return

        const scrollPos = this.options.container.scrollTop

        // eslint-disable-next-line eqeqeq
        if (scrollPos != 0) {
          $(this.options.thead).find('th').addClass('sticky')
        } else {
          $(this.options.thead).find('th').removeClass('sticky')
        }
      },

      _onRowClick: function (event) {
        const row = event.currentTarget
        this.focusRow(row)
        this.selectRow(row)

        const f = this.options.onRowClick.bind(this.tableElement)
        f(event)
      },

      _onRowDblClick: function (event) {
        const row = event.currentTarget
        this.focusRow(row)
        this.selectRow(row)

        const f = this.options.onRowDblClick.bind(this.tableElement)
        f(event)
      },

      _onRowKeyUp: function (event) {
        // имитация клика по завершению навигации в таблице
        const focusedRow = this.getFocusedRow()
        switch (event.keyCode) {
          // case 9:		// tab
          case 33: // pageup
          case 34: // pagedown
          case 35: // end
          case 36: // home
          case 38: // up
          case 40: // down
            event.preventDefault()
            $(focusedRow).click()
            break
          default:
        }
      },

      _onRowKeyDown: function (event) {
        const selectedRow = this.getSelectedRow()
        switch (event.keyCode) {
          case 13: // enter
            event.preventDefault()
            $(selectedRow).click()
            $(selectedRow).dblclick()
            break

          case 32: // space
            event.preventDefault()
            $(selectedRow).click()
            break

          case 33: // pageup
            event.preventDefault()
            // eslint-disable-next-line no-case-declarations
            const $prevRow24 = this.getPrev(24)
            if ($prevRow24) {
              this.focusRow($prevRow24[0])
            }
            break

          case 34: // pagedown
            event.preventDefault()
            // eslint-disable-next-line no-case-declarations
            const $nextRow24 = this.getNext(24)
            if ($nextRow24) {
              this.focusRow($nextRow24[0])
            }
            break

          case 38: // up
            event.preventDefault()
            // eslint-disable-next-line no-case-declarations
            const $prevRow = this.getPrev(1)
            if ($prevRow) {
              this.focusRow($prevRow[0])
            }
            break

          case 36: // home
            event.preventDefault()
            // eslint-disable-next-line no-case-declarations
            const $firstRow = this.getFirst()
            this.focusRow($firstRow[0])
            break

          case 35: // end
            event.preventDefault()
            // eslint-disable-next-line no-var
            var $lastRow = this.getLast()
            this.focusRow($lastRow[0])
            break

          case 40: // down
            event.preventDefault()
            // eslint-disable-next-line no-case-declarations
            const $nextRow = this.getNext(1)
            if ($nextRow) {
              this.focusRow($nextRow[0])
            }
            break
        }
      },

      getFirst: function () {
        return this.tableElement.find('tbody > tr:first')
      },

      getLast: function () {
        return this.tableElement.find('tbody > tr:last')
      },

      getPrev: function (n) {
        const focusedRow = this.getFocusedRow()
        let prev = focusedRow ? $(focusedRow) : $(this.getSelectedRow())

        do {
          if (prev.prev().length) {
            prev = prev.prev()
          } else {
            break
          }

          n--
        } while (n > 0)
        return prev
      },

      getNext: function (n) {
        const focusedRow = this.getFocusedRow()
        let next = focusedRow ? $(focusedRow) : $(this.getSelectedRow())

        do {
          if (next.next().length) {
            next = next.next()
          } else {
            break
          }
          n--
        } while (n > 0)
        return next
      },
    })

    /**
     * Странно, но методы в $.widget вместо undefined возвращают ссылку
     * на this.tableElement, что не подходит для некоторых методов.
     * Поэтому вместо
     * undefined мы возвращаем null, а данный метод создан для удобства.
     * @param v значение
     */
    function undef2null(v) {
      return typeof v === 'undefined' ? null : v
    }

    function pad(str, max) {
      str = str.toString()
      return str.length < max ? pad('0' + str, max) : str
    }

    $.tablesorter.addParser({
      id: 'typecomp',
      is: (s) => false,
      format: (typecomp) => {
        let comp = typecomp.toLowerCase()
        if (!comp.startsWith('"')) {
          comp = comp.split(' ').slice(1).join(' ')
        }
        comp = comp.slice(1, -1)
        return comp
      },
      type: 'text',
    })

    $.tablesorter.addParser({
      id: 'zay/nomer',
      is: (s) => false,
      format: (s) => {
        if (!s) {
          return '0000/00'
        }
        const zn = s.split('/')
        const padded = pad(zn[0], 4) + '/' + pad(zn[1], 2)
        return padded
      },
      type: 'text',
    })

    $.tablesorter.addParser({
      id: 'dd.mm.yyyy',
      is: (s) => false,
      format: (s) => {
        if (!s) return 0
        const d = $.fn.dateutils.str_ddmmyyyy2date(s, '.')
        const ts = $.fn.dateutils.d2ts(d)
        return ts
      },
      type: 'numeric',
    })

    $.tablesorter.addParser({
      id: 'nscheta',
      is: (s) => false,
      format: (s) => {
        let strpart, numpart
        for (let i = 0; i < s.length; i++) {
          if (!isNaN(parseInt(s[i]))) {
            strpart = s.slice(0, i)
            numpart = s.slice(i)
            const res = strpart + pad(numpart, 8)
            return res
          }
        }
        console.warn('Sort error for: ' + s)
        return s
      },
      type: 'text',
    })

    $.tablesorter.addParser({
      id: 'csnum',
      is: (s) => false,
      format: (s) => {
        s = s.replace(/,/g, '', s)
        const n = parseFloat(s)
        if (isNaN(n)) console.warn('Sort error for: ' + s)
        return n
      },
      type: 'numeric',
    })
  })(jQuery)
}
