import { settings } from '../app.config'

/** @deprecated */
export function GruzFirms($resource) {
  return $resource(
    `${settings.apiV1Url}/api.php?url=firms&id=:firmId`,
    {},
    {
      query: { isArray: true },
    }
  )
}

/** @deprecated */
export function GruzPricep($resource) {
  return $resource(
    `${settings.apiV1Url}/api.php?url=priceps&firm_id=:firmId`,
    {},
    {
      query: { isArray: true },
    }
  )
}

/** @deprecated */
export function GruzAvto($resource) {
  return $resource(
    `${settings.apiV1Url}/api.php?url=avtos&firm_id=:firmId`,
    {},
    {
      query: { isArray: true },
    }
  )
}
