import { FormController } from '../../controllers/form-controller.class'

class AvtoFormController extends FormController {
  /** @ngInject */
  constructor($scope, $injector, $stateParams, avtoServiceApiV2) {
    super($scope, $injector)
    this.$stateParams = $stateParams
    this.api = avtoServiceApiV2
  }

  /** Инициализация компонента. */
  $onInit() {
    this.model = Object.assign(
      {
        driver: '',
        polu: '',
        type: 'Г',
        fuel: 'Д',
      },
      this.model
    )
  }
}

export const AvtoFormComponent = {
  template: require('./avto-form.component.html'),
  styles: [require('./avto-form.component.scss')],
  bindings: {
    onSave: '&',
    onClose: '&',
    model: '<',
    tachographs: '<',
  },
  controller: AvtoFormController,
}
