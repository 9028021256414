import { settings } from '../../app.config'
import { ListController } from '../../controllers/list-controller.class'
import utils, {
  currentMonth,
  firstDayOfMonth,
  lastDayOfMonth,
} from '../../utils'

/** @ngInject */
class GruzaperFormController extends ListController {
  constructor($scope, $injector, printer, planServiceApiV2) {
    super($scope, $injector)
    $scope.vm = this
    this.printer = printer
    this.api = planServiceApiV2
    this.tableOptions = {
      headers: {
        pid: 'Файл',
        nomer: 'Номер',
        data_: 'Дата',
        eksp__fio: 'Экспедитор',
        name: 'Груз',
      },
    }
  }

  get defaultFilters() {
    return {
      eksp: null,
      m1: currentMonth() + 1,
      m2: currentMonth() + 1,
      nashi: false,
    }
  }

  /** Фильтры для таблицы перевозок. */
  get plansFilters() {
    const filters = { ...this.filters }
    filters.date_from = utils.db.date2str(firstDayOfMonth(this.filters.m1 - 1))
    filters.date_to = utils.db.date2str(lastDayOfMonth(this.filters.m2 - 1))
    delete filters.m1
    delete filters.m2
    return filters
  }

  rowFormatter(row) {
    const eksp = row.eksp_info || {}
    return {
      ...row,
      data_: utils.db.date2dmy(row.data),
      eksp__fio: eksp.name,
    }
  }

  print() {
    this.printer.print('vyvgruz', {
      eksp: this.filters.eksp,
      m1: this.filters.m1,
      m2: this.filters.m2,
      year: settings.defaultYear,
      nashi: this.filters.nashi,
    })
  }
}

export const GruzaperFormComponent = {
  template: require('./gruzaper-form.component.html'),
  styles: [require('./gruzaper-form.component.scss')],
  bindings: {
    ekspeditors: '<',
  },
  controller: GruzaperFormController,
  controllerAs: 'vm',
}
