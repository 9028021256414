// eslint-disable-next-line max-len
import { PrintFormController } from '../../controllers/print-form-controller.class'
import utils, { firstDayOfMonth, lastDayOfMonth } from '../../utils'

/** @ngInject */
class PlanperFormController extends PrintFormController {
  reportName = 'plan_period'

  get defaultFilters() {
    return {
      eksp: '1',
      ename: null,
      data1: firstDayOfMonth(),
      data2: lastDayOfMonth(),
      mgorod: '3',
    }
  }

  get convertedFilters() {
    return {
      ...this.filters,
      ...{
        data1: utils.db.date2dmy(this.filters.data1),
        data2: utils.db.date2dmy(this.filters.data2),
      },
    }
  }
}

export const PlanperFormComponent = {
  template: require('./planper-form.component.html'),
  styles: [require('./planper-form.component.scss')],
  bindings: {
    ekspeditors: '<',
  },
  controller: PlanperFormController,
  controllerAs: 'vm',
}
