import { ListController } from '../../controllers/list-controller.class'

/** @ngInject */
class CorrectFormController extends ListController {
  constructor(
    $scope,
    $injector,
    printer,
    popup,
    correctService,
    correctModal,
    $state
  ) {
    super($scope, $injector)
    this.printer = printer
    this.api = correctService
    this.modal = correctModal
    this.popup = popup
    this.$state = $state
    this.tableOptions = {
      headers: {
        tag: 'Тягач',
        zdriver: 'Закр. водитель',
        zpricep: 'Закр. прицеп',
        realdriver: 'Водитель',
        realpricep: 'Прицеп',
        mesto: 'Местонахождение',
      },
    }
  }

  print() {
    this.printer.printLegacy('printplaces')
  }
}

export const CorrectFormComponent = {
  template: require('./correct-form.component.html'),
  styles: [require('./correct-form.component.scss')],
  bindings: {},
  controller: CorrectFormController,
}
