/** @ngInject */
class MenuColumnController {
  /**
   * Корневой узел.
   * @type {MenuItem}
   */
  root

  constructor($scope, currentUser, popup) {
    $scope.vm = this
    this.hovering = false
    this.over = false
    this.currentUser = currentUser
    this.popup = popup
  }

  /**
   * Меню спрятано.
   * @returns {boolean}
   */
  get hidden() {
    return !this.hovering && !this.active
  }

  $onInit() {
    this.items = this.root.children.filter((item) =>
      this.currentUser.hasMenuItemAccess(item)
    )
  }

  openItem(item) {
    this.popup.openMenuItem(item)
  }
}

export const MenuColumnComponent = {
  template: require('./menu-column.component.html'),
  styles: [require('./menu-column.component.scss')],
  bindings: {
    root: '<',
    active: '<',
  },
  controller: MenuColumnController,
}
